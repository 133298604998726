import { useEffect, useRef } from 'react';
import { type IConfig } from '@4tn/core-audio-player-v2';
import { useDataContext } from '../../store/data-context';
import { isChrome, isIframe } from '../../util/device';
import { usePlayerContext } from '../../store/player-context';
import { logger } from '../../util';
import { useSelectors } from './App.selectors';
import { ActivePodcastOrStation } from '@4tn/core-audio-player-v2';
import { getCSID } from '../../util/getCSID';
import { handlePlayStream } from './App.utils';

export const useCapConfig = (): IConfig => {
  const { player } = useDataContext();

  const tcString = window['tcString'] || '';

  const userToken: string = new URLSearchParams(window.location.search).get('userToken') || '';
  const { shouldExcludePreroll } = useSelectors();

  return {
    distTag: player?.distTag,
    excludePreroll: shouldExcludePreroll(),
    newTealiumSetup: true,
    gdpr: 1,
    ttag: '',
    gdprConsent: tcString,
    userToken,
    hdStream: Boolean(userToken),
    csid: getCSID(player?.slug),
  };
};

export const useAutoplay = (activeMedia: ActivePodcastOrStation, config: IConfig): void => {
  const { playerState, setPlayerState } = usePlayerContext();
  const autoplayTriggered = useRef(false);

  useEffect(
    function handleAutoplay() {
      const isAutoplayParamSet = new URLSearchParams(window.location.search).get('autoplay') === 'true';
      const shouldTriggerAutoplay =
        Boolean(activeMedia && isAutoplayParamSet && !autoplayTriggered.current) && isIframe();
      if (shouldTriggerAutoplay) {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();

        // AudioContext state is not running when permision is default or mute in case of Chrome or if in Safari (Firefox works out of the box)
        // This solution will fix infinite loading state
        if (audioContext.state !== 'running' && isChrome()) {
          config.excludePreroll = true;
        }

        setPlayerState({ ...playerState, isLoading: true });
        handlePlayStream(activeMedia, config)
          .then(() => {
            autoplayTriggered.current = true;
          })
          .catch((error) => {
            logger.error(error);
            setPlayerState({ ...playerState, isLoading: false, isPlaying: false, hasError: true });
            autoplayTriggered.current = true;
          });
      }
    },
    [activeMedia, window.location.href]
  );
};
