const tcfApiLocator = '__tcfapiLocator';

export function getCmpFrame(): Window | null {
  let frame: Window = window;
  let cmpFrame: Window = null;

  while (frame) {
    try {
      if (frame.frames[tcfApiLocator]) {
        cmpFrame = frame;
        break;
      }
    } catch (ignore) {}

    if (frame === window.top) {
      break;
    }

    frame = frame.parent;
  }

  return cmpFrame;
}
