import { TcfApiCallback } from '../types';
import { getCmpFrame } from './getCmpFrame';
import { waitFor } from './waitFor';

const cmpCallbacks = {};

export const addTcfProxy = async (): Promise<void> => {
  const cmpFrame = await waitFor<Window>(() => getCmpFrame());

  if (!cmpFrame) return;

  window.__tcfapi = (cmd: string, version: number, callback: TcfApiCallback, arg?: unknown): void => {
    const callId = Math.random() + '';

    const msg = {
      __tcfapiCall: {
        command: cmd,
        parameter: arg,
        version: version,
        callId: callId,
      },
    };
    // map the callback for lookup on response
    cmpCallbacks[callId] = callback;

    cmpFrame.postMessage(msg, '*');
  };

  const postMessageHandler = (event: any): void => {
    // when we get the return message, call the mapped callback
    let json: any = {};

    try {
      // if this isn't valid JSON then this will throw an error
      json = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;
    } catch (ignore) {}

    const payload = json.__tcfapiReturn;

    if (payload) {
      // messages we care about will have a payload
      if (typeof cmpCallbacks[payload.callId] === 'function') {
        // call the mapped callback and then remove the reference
        cmpCallbacks[payload.callId](payload.returnValue, payload.success);

        // Todo, clear cmpCallbacks for removeEventListener
        if (payload.command !== 'addEventListener') {
          cmpCallbacks[payload.callId] = null;
        }
      }
    }
  };

  window.addEventListener('message', postMessageHandler, false);
};
