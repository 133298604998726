import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './components/App';
import ErrorBoundary from './components/ErrorBoundary';
import { QueryClientProvider } from 'react-query';
import { queryClient } from './api/queryClient';
import PlayerThemeProvider from './components/PlayerThemeProvider';
import { DataContextProvider } from './store/data-context';
import { BrowserRouter } from 'react-router-dom';
import FlagsProvider from './firebase/FlagsProvider';
import { PlayerContextProvider } from './store/player-context';
import { DEFAULT_REMOTE_CONFIG_FLAGS } from './firebase/const';
import storage from './util/storage';
import { ConsentProvider } from './components/ConsentProvider';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <QueryClientProvider client={queryClient}>
    <DataContextProvider>
      <PlayerContextProvider>
        <PlayerThemeProvider>
          <ErrorBoundary>
            <BrowserRouter>
              <ConsentProvider>
                {storage.isEnabled() ? (
                  <FlagsProvider defaults={DEFAULT_REMOTE_CONFIG_FLAGS}>
                    <App />
                  </FlagsProvider>
                ) : (
                  <App />
                )}
              </ConsentProvider>
            </BrowserRouter>
          </ErrorBoundary>
        </PlayerThemeProvider>
      </PlayerContextProvider>
    </DataContextProvider>
  </QueryClientProvider>
);
