import React, { useCallback, useEffect, useRef, useState } from 'react';
import { player as capPlayer, events, PlayerEvents, AdActions, playerStateManager } from '@4tn/core-audio-player-v2';
import { useNavigate } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { v4 as uuid } from 'uuid';
import Swimlane from '../../components/Swimlane';
import Controls from '../Controls';
import NowPlaying from '../NowPlaying';
import EpisodesIcon from '../svg/EpisodesIcon';
import CloseIcon from '../svg/CloseIcon';
import RadioPodcastIcon from '../svg/RadioPodcastIcon';
import VolumeIcon from '../svg/VolumeIcon';
import TabItem from '../TabItem';
import VolumeSlider from '../VolumeSlider';
import Scrubber from '../Scrubber';
import { styles } from './App.style';
import classNames from 'classnames';
import { useDataContext } from '../../store/data-context';
import { useTheme } from 'theming';
import { ThemeObject } from '../theme/theme';
import useBrowser from '../../hooks/useBrowser';
import { isMobile, isTablet } from 'react-device-detect';
import { getSlugForStationOrPodcast, getSlugForPodcastEpisode } from '../../util/getStationOrPodcastSlug';
import { freewheelTimeout, userClickEventLabel } from '../../globalConst/const';
import { logger } from '../../util';
import { streamMenuPlayTealiumEventHandler } from '../../analytics/event-handlers/streamMenuPlayTealiumEventHandler';
import { streamLoadMediaEventHandler } from '../../analytics/event-handlers/streamLoadMediaTealiumEventHandler';
import { streamClickTealiumEventHandler } from '../../analytics/event-handlers/streamClickTealiumEventHandler';
import { streamOutboundClickTealiumEventHandler } from '../../analytics/event-handlers/streamOutboundClickTealiumEventHandler';
import { useAutoplay, useCapConfig } from './App.hooks';
import { IStationOrPodcast } from '../../hooks/usePlayerData';
import { usePlayerContext } from '../../store/player-context';
import { reportSpecificError } from '../../metrics/reportSpecificError';
import { PlayerErrorEvent } from '../../types';
import { handlePlayStream } from './App.utils';
import storage from '../../util/storage';

const App = (): JSX.Element => {
  const theme: ThemeObject = useTheme();
  const classes = styles();
  const splittedPathName = window.location.pathname.split('/');
  const [showMobileOverlay, setShowMobileOverlay] = useState<boolean>(false);
  const { stationsAndPodcasts, player } = useDataContext();
  const [isLiveAudio, setIsLiveAudio] = useState(false);
  const [activePodcastOrStation, setActivePodcastOrStation] = useState<IStationOrPodcast>(null);
  const [activeMedia, setActiveMedia] = useState(null);
  const [activeEpisodes, setActiveEpisodes] = useState(null);
  const [playoutHolder, setPlayoutHolder] = useState(null);
  const controlsRef = useRef<HTMLDivElement>();

  const [volumeLevel, setVolumeLevel] = useState(1);
  const { playerState, setPlayerState, currentTime, setCurrentTime } = usePlayerContext();

  const coreAudioPlayerConfig = useCapConfig();

  const allStationsAndPodcasts = stationsAndPodcasts.all;

  const [online, isOnline] = useState(navigator.onLine);
  const browser = useBrowser();
  const browserSizeMobile = browser.down(820);

  const navigate = useNavigate();

  useAutoplay(activeMedia, coreAudioPlayerConfig);

  const handleSetVolumeLevel = (level: number) => {
    setVolumeLevel(level);
    storage.setItem('volumeLevel', level.toString());
  };

  const setOnline = () => {
    isOnline(true);
  };
  const setOffline = () => {
    isOnline(false);
  };

  useEffect(() => {
    capPlayer.initAnalytics();
  }, []);

  useEffect(() => {
    playerStateManager.setState({ mediaMetadata: activeMedia });
    streamLoadMediaEventHandler(activeMedia);
  }, [activeMedia]);

  useEffect(() => {
    // Set default volume level from localStorage if exists
    try {
      const storedVolumeLevel = storage.getItem('volumeLevel');
      handleSetVolumeLevel(storedVolumeLevel ? Number.parseFloat(storedVolumeLevel) : 1);
    } catch (error) {
      logger.error(error);
    }
  }, []);

  const addJWVideoPlayerStyles = () => {
    const overlay = document.createElement('div');
    overlay.id = 'overlay';

    document.getElementById('root').appendChild(overlay);

    const jwSlider = document.getElementsByClassName('jw-slider-vertical')[0] as HTMLElement;

    if (jwSlider) {
      jwSlider.style.maxHeight = '80px';
    }
  };

  const adStart = () => {
    addJWVideoPlayerStyles();
  };

  const adBlockerDetected = () => {
    document.getElementById('overlay')?.remove();
  };

  const adStop = () => {
    document.getElementById('overlay')?.remove();
  };

  useEffect(
    function setActivePodcastOrStationEffect() {
      if (!stationsAndPodcasts.all.length) {
        return;
      }
      const [, , stationsOrPodcasts, brand, stationOrPodcast] = splittedPathName;
      setActivePodcastOrStation(
        stationsOrPodcasts &&
          brand &&
          stationOrPodcast &&
          stationsAndPodcasts[stationsOrPodcasts][brand][stationOrPodcast]
          ? stationsAndPodcasts[stationsOrPodcasts][brand][stationOrPodcast]
          : allStationsAndPodcasts[0]
      );
    },
    [allStationsAndPodcasts, setActivePodcastOrStation]
  );

  useEffect(
    function setActiveEpisodesEffect() {
      if (activePodcastOrStation?.getClips?.clips) {
        setActiveEpisodes({ clips: activePodcastOrStation.getClips.clips, name: activePodcastOrStation.title });
      }
    },

    [activePodcastOrStation]
  );

  // Needed on app loading
  useEffect(
    function setActiveMediaEffect() {
      if (activePodcastOrStation) {
        if (activePodcastOrStation?.getClips?.clips) {
          const [, , , , , channelOrEpisode] = splittedPathName;
          if (channelOrEpisode) {
            activePodcastOrStation.getClips.clips.forEach((episode) => {
              if (channelOrEpisode === episode.slug) {
                setActiveMedia({ ...episode, podcastTitle: activePodcastOrStation.title });
              }
            });
          } else {
            const media = {
              ...activePodcastOrStation?.getClips?.clips[0],
              podcastTitle: activePodcastOrStation.title,
              images: activePodcastOrStation.images,
            };
            setActiveMedia(media);
          }
        } else {
          setActiveMedia(activePodcastOrStation);
        }
      }
    },
    [activePodcastOrStation]
  );

  const handleSetActivePodcastOrStation = useCallback(
    (item, config) => async () => {
      const path = getSlugForStationOrPodcast(stationsAndPodcasts, item);
      if (path) navigate(`${player.slug}/${path}`);

      setPlayoutHolder(null);

      if (!item) {
        return;
      }

      if (item.getClips?.clips) {
        setCurrentTime(0);
        setActiveEpisodes({ clips: item.getClips.clips, name: item.title });

        const [, , , , , channelOrEpisode] = splittedPathName || [];
        const episode = item.getClips.clips.find((ep) => ep.slug === channelOrEpisode);
        const media = episode
          ? { ...episode, podcastTitle: item.title, images: episode.images || item.images }
          : { ...item?.getClips?.clips[0], podcastTitle: item.title, images: item.images };

        setPlayerState({ ...playerState, isLoading: true });
        setActiveMedia(media);
        streamMenuPlayTealiumEventHandler(media);
        await handlePlayStream(media, { ...config, volume: volumeLevel });
      } else {
        setPlayerState({ ...playerState, isLoading: true });
        setActiveMedia(item);
        streamMenuPlayTealiumEventHandler(item);
        await handlePlayStream(item, { ...config, volume: volumeLevel });
      }
    },
    [setActivePodcastOrStation, playerState, setPlayerState, volumeLevel, stationsAndPodcasts]
  );

  const handleSetActivePodcast = useCallback(
    function setActivePodcastCallback(item, config) {
      const activePodcast = { ...item, podcastTitle: activeMedia?.podcastTitle };
      return async function setActivePodcastThunk() {
        streamMenuPlayTealiumEventHandler(activePodcast);

        const path = getSlugForPodcastEpisode(stationsAndPodcasts.podcasts, activePodcast);
        if (path) {
          navigate(`${player.slug}/${path}`);
        }

        if (activePodcast) {
          setPlayerState({ ...playerState, isLoading: true });
          setCurrentTime(0);
          setActiveMedia(activePodcast);
          await handlePlayStream(activePodcast, { ...config, volume: volumeLevel });
        }
      };
    },
    [setActivePodcastOrStation, playerState, setPlayerState, volumeLevel, player?.slug]
  );

  const onTimeUpdate = (event) => {
    setCurrentTime(event.time);
  };

  const onStreamPlay = (event) => {
    setIsLiveAudio(event.isLiveAudio);
    setShowMobileOverlay(false);

    try {
      // In case previos volume level was 0 (stream muted) unmute it to volume 1
      const previousVolumeLevel = storage.getItem('volumeLevel');
      if (previousVolumeLevel === '0' && !playerState.isPlaying) {
        handleSetVolumeLevel(1);
        capPlayer.setVolumeLevel(1);
      }
    } catch (error) {
      logger.error(error);
    }
    setPlayerState((state) => ({
      ...state,
      isPlaying: true,
      isLoading: false,
      hasError: false,
      sessionId: uuid(),
    }));
  };

  const onPlayerPlaying = () => {
    setPlayerState((state) => ({
      ...state,
      isPlaying: true,
      isLoading: false,
    }));
  };

  const onPlayerDisconnected = () => {
    setPlayerState((state) => ({
      ...state,
      isPlaying: false,
      isLoading: false,
    }));
  };

  const onPlayerReconnecting = () => {
    setPlayerState((state) => ({
      ...state,
      isPlaying: false,
      isLoading: true,
    }));
  };

  const onStreamStop = () => {
    setPlayerState((state) => ({
      ...state,
      isPlaying: false,
    }));
  };

  const onStreamEnded = () => {
    setPlayerState((state) => ({
      ...state,
      isPlaying: false,
      isLoading: false,
    }));
    setShowMobileOverlay(false);
  };

  const onStreamError = (event: PlayerErrorEvent) => {
    setPlayerState((playerState) => ({
      ...playerState,
      isPlaying: false,
      isLoading: false,
      hasError: true,
    }));
    setShowMobileOverlay(false);
    reportSpecificError({
      errorType: event?.name,
      distTag: event?.distTag,
      streamUrl: event?.streamUrl,
      streamType: event?.streamType,
    });
    logger.error(JSON.stringify(event));
  };

  useEffect(() => {
    events.on(PlayerEvents.STREAM_START, onStreamPlay);
    events.on(PlayerEvents.STREAM_RESUMED, onStreamPlay);
    events.on(PlayerEvents.PLAYER_PLAYING, onPlayerPlaying);
    events.on(PlayerEvents.STREAM_PAUSE, onStreamStop);
    events.on(PlayerEvents.STREAM_STOP, onStreamStop);
    events.on(PlayerEvents.PLAYER_DISCONNECTED, onPlayerDisconnected);
    events.on(PlayerEvents.PLAYER_RECONNECTING, onPlayerReconnecting);
    events.on(PlayerEvents.TIME_UPDATE, onTimeUpdate);
    events.on(PlayerEvents.STREAM_ENDED, onStreamEnded);
    events.on(PlayerEvents.STREAM_ERROR, onStreamError);
    events.on(AdActions.AD_START, adStart);
    events.on(AdActions.AD_BLOCKER_DETECTED, adBlockerDetected);
    events.on(AdActions.AD_STOP, adStop);

    return () => {
      events.off(PlayerEvents.STREAM_START, onStreamPlay);
      events.off(PlayerEvents.STREAM_RESUMED, onStreamPlay);
      events.off(PlayerEvents.PLAYER_PLAYING, onPlayerPlaying);
      events.off(PlayerEvents.STREAM_PAUSE, onStreamStop);
      events.off(PlayerEvents.STREAM_STOP, onStreamStop);
      events.off(PlayerEvents.PLAYER_DISCONNECTED, onPlayerDisconnected);
      events.off(PlayerEvents.PLAYER_RECONNECTING, onPlayerReconnecting);
      events.off(PlayerEvents.TIME_UPDATE, onTimeUpdate);
      events.off(PlayerEvents.STREAM_ENDED, onStreamEnded);
      events.off(PlayerEvents.STREAM_ERROR, onStreamError);
      events.off(AdActions.AD_START, adStart);
      events.off(AdActions.AD_BLOCKER_DETECTED, adBlockerDetected);
      events.off(AdActions.AD_STOP, adStop);
    };
  }, [activeMedia, playerState]);

  useEffect(() => {
    window.addEventListener('offline', setOffline);
    window.addEventListener('online', setOnline);

    return () => {
      window.removeEventListener('offline', setOffline);
      window.removeEventListener('online', setOnline);
    };
  }, [online]);

  if (coreAudioPlayerConfig.excludePreroll) {
    storage.removeItem(freewheelTimeout);
  }

  const brandImageUri = player?.images[0]?.uri;
  const logoImage = brandImageUri || theme.jukeLogo;
  const isLogoImageSquare = player?.images[0]?.width && player?.images[0]?.width === player?.images[0]?.height;
  const logoImgText = brandImageUri ? '' : 'Powered by JUKE';
  let logoHref = 'https://www.juke.nl';

  if (brandImageUri) {
    logoHref = '';
  }

  if (brandImageUri && player?.link) {
    logoHref = player?.link;
  }
  const hrefTitle = brandImageUri ? '' : 'Juke.nl';

  const playerClickEvent = (label) => {
    streamClickTealiumEventHandler(label, playerState.isPlaying);
  };

  const sendAnalytics = (link: string) => {
    streamOutboundClickTealiumEventHandler(link, playerState.isPlaying);
  };

  return (
    <>
      <Tabs className={classes.appWrapper} onSelect={() => setShowMobileOverlay(true)}>
        <div id="preroll" style={{ display: 'none' }} />
        <div
          className={classNames(classes.topPanel, {
            [classes.overlayActive]: showMobileOverlay,
          })}
        >
          <div className={classes.closeOverlay} onClick={() => setShowMobileOverlay(false)}>
            <CloseIcon />
          </div>
          {stationsAndPodcasts?.all?.length > 1 && (
            <TabPanel>
              <p className={classes.panelTitle}>Radio &amp; Podcasts</p>
              <Swimlane
                items={allStationsAndPodcasts}
                peekNextItem
                itemsPerRow={5}
                dots
                isOnline={online}
                showControlButton={false}
                handleSetActivePodcastOrStation={handleSetActivePodcastOrStation}
                activePodcastOrStation={activeMedia}
                config={coreAudioPlayerConfig}
              />
            </TabPanel>
          )}
          {activeMedia?.contentTypeId === 'podcast' && (
            <TabPanel>
              <p className={classes.panelTitle}>Afleveringen</p>
              <Swimlane
                items={activeEpisodes.clips}
                peekNextItem
                itemsPerRow={5}
                dots
                isOnline={online}
                showControlButton={false}
                handleSetActivePodcastOrStation={handleSetActivePodcast}
                activePodcastOrStation={activeMedia}
                config={coreAudioPlayerConfig}
              />
            </TabPanel>
          )}
          {!isMobile && !isTablet && (
            <TabPanel>
              <p className={classes.panelTitle}>Volume aanpassen</p>
              <VolumeSlider
                volumeLevel={volumeLevel}
                setVolumeLevel={handleSetVolumeLevel}
                isLoading={playerState.isLoading}
              />
            </TabPanel>
          )}
        </div>
        {browserSizeMobile && (
          <div className={classes.bottomPanelMobile}>
            <div className={classes.radioPlayerMobile}>
              <div className={classes.topPanelMobile}>
                <NowPlaying
                  activePodcastOrStation={activeMedia}
                  name={activeEpisodes?.name}
                  isOnline={online}
                  playoutHolder={playoutHolder}
                  setPlayoutHolder={setPlayoutHolder}
                  controlsRef={controlsRef}
                />
                <Controls
                  media={activeMedia}
                  config={coreAudioPlayerConfig}
                  online={online}
                  currentTime={currentTime}
                  podcastTitle={activeEpisodes?.name}
                  isLiveAudio={isLiveAudio}
                  volumeLevel={volumeLevel}
                  ref={controlsRef}
                />
              </div>
              <div className={classes.bottomTabsWrapperMobile}>
                <div className={classes.logoMobile}>
                  <a
                    {...(logoHref ? { href: logoHref } : {})}
                    onClick={() => {
                      sendAnalytics(logoHref);
                    }}
                    className={isLogoImageSquare ? classes.logoHrefSquare : classes.logoHref}
                    target="_blank"
                    title={hrefTitle}
                    rel="noreferrer"
                  >
                    <img
                      src={logoImage}
                      className={isLogoImageSquare ? classes.logoImageSquare : classes.logoImage}
                      title={logoImgText}
                      alt={logoImgText}
                    />
                  </a>
                </div>
                <TabList className={classes.tabList}>
                  <div className={classes.tabListWrapperMobile}>
                    {stationsAndPodcasts?.all?.length > 1 && (
                      <Tab
                        className={classNames(classes.tabItem, classes.tabItemWrapper)}
                        onClick={() => playerClickEvent(userClickEventLabel.MEDIA_LIST)}
                      >
                        <TabItem>
                          <div className={classes.svgIconWrapperMobile}>
                            <RadioPodcastIcon />
                          </div>
                          <p className={classes.tabItemTitle}>Radio &amp; Podcasts</p>
                        </TabItem>
                      </Tab>
                    )}
                    {activeMedia?.contentTypeId === 'podcast' && (
                      <Tab
                        className={classNames(classes.tabItem, classes.tabItemWrapper)}
                        onClick={() => playerClickEvent(userClickEventLabel.EPISODE_LIST)}
                      >
                        <TabItem>
                          <div className={classes.svgIconWrapperMobile}>
                            <EpisodesIcon />
                          </div>
                          <p className={classNames(classes.tabItemTitle, classes.tabItemTitleEllipsis)}>Afleveringen</p>
                        </TabItem>
                      </Tab>
                    )}
                    {!isMobile && !isTablet && (
                      <Tab
                        className={classNames(
                          classes.tabItem,
                          classes.tabItemWrapper,
                          !online ? classes.disabled : null
                        )}
                        onClick={() => playerClickEvent(userClickEventLabel.VOLUME)}
                      >
                        <TabItem>
                          <div className={classes.svgIconWrapperMobile}>
                            <VolumeIcon />
                          </div>
                          <p className={classes.tabItemTitle}>Volume</p>
                        </TabItem>
                      </Tab>
                    )}
                  </div>
                </TabList>
              </div>
            </div>
          </div>
        )}
        {!browserSizeMobile && (
          <div className={classes.bottomPanel}>
            <div
              className={classNames({
                [classes.radioPlayerMobile]: browserSizeMobile,
                [classes.radioPlayer]: !browserSizeMobile,
              })}
            >
              <NowPlaying
                activePodcastOrStation={activeMedia}
                name={activeEpisodes?.name}
                isOnline={online}
                playoutHolder={playoutHolder}
                setPlayoutHolder={setPlayoutHolder}
                controlsRef={controlsRef}
              />

              <div className={classes.controls}>
                <Controls
                  media={activeMedia}
                  currentTime={currentTime}
                  config={coreAudioPlayerConfig}
                  online={online}
                  isLiveAudio={isLiveAudio}
                  podcastTitle={activeEpisodes?.name}
                  volumeLevel={volumeLevel}
                  ref={controlsRef}
                />
                {activeMedia?.contentTypeId === 'podcast' && <Scrubber activePodcast={activeMedia} isOnline={online} />}
              </div>

              <div className={classes.bottomTabsWrapper}>
                <div className={classes.logo}>
                  <a
                    {...(logoHref ? { href: logoHref } : {})}
                    onClick={() => {
                      sendAnalytics(logoHref);
                    }}
                    className={isLogoImageSquare ? classes.logoHrefSquare : classes.logoHref}
                    target="_blank"
                    title={hrefTitle}
                    rel="noreferrer"
                  >
                    <img
                      src={logoImage}
                      className={isLogoImageSquare ? classes.logoImageSquare : classes.logoImage}
                      title={logoImgText}
                      alt={logoImgText}
                    />
                  </a>
                </div>
                <TabList className={classes.tabList}>
                  <div className={classes.tabListWrapper}>
                    {stationsAndPodcasts?.all?.length > 1 && (
                      <Tab
                        className={classNames(classes.tabItem, classes.tabItemWrapper)}
                        onClick={() => playerClickEvent(userClickEventLabel.MEDIA_LIST)}
                      >
                        <TabItem>
                          <div className={classes.svgIconWrapper}>
                            <RadioPodcastIcon />
                          </div>
                          <p className={classes.tabItemTitle}>Radio &amp; Podcasts</p>
                        </TabItem>
                      </Tab>
                    )}
                    {activeMedia?.contentTypeId === 'podcast' && (
                      <Tab
                        className={classNames(classes.tabItem, classes.tabItemWrapper)}
                        onClick={() => playerClickEvent(userClickEventLabel.EPISODE_LIST)}
                      >
                        <TabItem>
                          <div className={classes.svgIconWrapper}>
                            <EpisodesIcon />
                          </div>
                          <p className={classNames(classes.tabItemTitle, classes.tabItemTitleEllipsis)}>Afleveringen</p>
                        </TabItem>
                      </Tab>
                    )}
                    {!isMobile && !isTablet && (
                      <Tab
                        className={classNames(
                          classes.tabItem,
                          classes.tabItemWrapper,
                          !online ? classes.disabled : null
                        )}
                        onClick={() => playerClickEvent(userClickEventLabel.VOLUME)}
                      >
                        <TabItem>
                          <div className={classes.svgIconWrapper}>
                            <VolumeIcon />
                          </div>
                          <p className={classes.tabItemTitle}>Volume</p>
                        </TabItem>
                      </Tab>
                    )}
                  </div>
                </TabList>
              </div>
            </div>
          </div>
        )}
      </Tabs>
    </>
  );
};

export default App;
