import { getPlatform } from './device';
import logger from './logger';

const partnerMap: Record<string, string> = {
  'nederland.fm': 'nederland_fm',
  'startpagina.nl': 'startpagina',
  'linda.nl': 'linda',

  // Added to facilitate testing, this is not a real partner.
  'playground-partnerplayer.juke.nl': 'playground',
};

const fallbackPartner = 'talpa_audio_partner_player';

const getPartner = (): string => {
  if (!document.referrer) return fallbackPartner;

  try {
    const partner = new URL(document.referrer).hostname.replace(/^www\./, '');
    return partnerMap[partner] || fallbackPartner;
  } catch (error) {
    logger.error('Error getting partner from referrer: ', error);
    return fallbackPartner;
  }
};

export const getCSID = (playerSlug: string): string => {
  const partner = getPartner();
  const platform = getPlatform();

  const formattedSlug = playerSlug ? `_${playerSlug.replaceAll('-', '_')}_` : '_';

  return `${partner}${formattedSlug}${platform}`.toLowerCase();
};
