export type ReturnEventType = { [key: string]: string | number | boolean };

export type PodcastEventTypes = { media_duration?: number; media_datepublished?: number; media_program_name?: string };

export type PlayerEvent = {
  media: {
    contentId?: string;
    contentType?: string;
    distTag?: string;
    name?: string;
    player?: string;
    streamType?: string;
  };
  uri?: string;
  streamStatus?: {
    code: string;
  };
};

export interface ErrorDetailsType {
  errorType?: string;
  distTag?: string;
  streamUrl?: string;
  streamType?: string;
  name?: string;
}

export interface PlayerErrorEvent {
  contentId?: string;
  contentType?: string;
  distTag?: string;
  name?: string;
  player?: string;
  streamType?: string;
  streamUrl?: string;
  streamStatus?: {
    code: string;
  };
}

export type TcfApiCallback = (data: { tcString: string; msg: string }, success: boolean) => void;

export type TcfApi = (cmd: string, version: number, callback: TcfApiCallback, arg?: unknown) => void;

declare global {
  interface Window {
    optimizelyReady: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    utag?: { link?: (param: any) => void };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    featureFlags: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    webkitAudioContext: any;
    __tcfapi?: TcfApi;
    tcString?: string;
  }
}

export enum SkipDirection {
  FORWARD = 'forward',
  BACKWARD = 'backward',
}

export interface TCData {
  [k: string]: unknown;
  tcString: string;
}

window.optimizelyReady = false;
