export const waitFor = <T = unknown>(condition: () => T, attempts = 5, delay = 100): Promise<T> => {
  let attempt = 0;

  return new Promise(async (resolve) => {
    const interval = setInterval(async () => {
      const conditionIsMet = condition();

      if (conditionIsMet || attempt >= attempts) {
        clearInterval(interval);
        resolve(conditionIsMet);
        return;
      }

      attempt++;
    }, delay);
  });
};
