import { TCData } from '../types';
import { addTcfProxy } from './addTcfProxy';
import logger from './logger';

const TCF_TIMEOUT = 3000;

export const getConsentFromParent = async (): Promise<string | null> => {
  await addTcfProxy();

  if (typeof window.__tcfapi !== 'function') return null;

  const getTcString = new Promise<string | null>((resolve) =>
    window.__tcfapi('addEventListener', 2, (tcData: TCData) => resolve(tcData?.tcString || null))
  );

  let timeoutId: ReturnType<typeof setTimeout>;

  const timeout = new Promise<null>(
    (resolve) =>
      (timeoutId = setTimeout(() => {
        logger.log(`No response from __tcfapi after ${TCF_TIMEOUT}ms`);
        delete window.__tcfapi;
        resolve(null);
      }, TCF_TIMEOUT))
  );

  try {
    return Promise.race([getTcString, timeout]);
  } catch (error) {
    logger.log('Getting TC string resulted in error:', error);
  } finally {
    clearTimeout(timeoutId);
  }

  return null;
};
