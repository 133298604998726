import React, { useState, useEffect, ReactNode, ReactElement } from 'react';
import { getConsentFromParent } from '../util/getConsentFromParent';
import logger from '../util/logger';

interface ConsentProviderProps {
  children: ReactNode;
}

export const ConsentProvider = ({ children }: ConsentProviderProps): ReactElement => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    getConsentFromParent()
      .then((result) => {
        if (result) {
          window.tcString = result;
        }
      })
      .catch((error) => {
        logger.log('Getting consent from parent failed:', error);
      })
      .finally(() => setInitialized(true));
  }, []);

  if (!initialized) return null;

  return <>{children}</>;
};
